//
// variables and mixins
//

@import "mixin";

//
// standard tag styles
//

body {
  line-height: 1.3;
  color: $fore-colour;
  background: $background-colour;
  font-family: $font-family;
  font-weight: $font-weight;
  font-size: 100%;
}

a {
  color: $lowlight-colour;
  &:active { color: $hilight-colour; }
  &:visited { color: $lowlight-colour; }

  img {
    border: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 1em;
  margin-bottom: 1em;
}

h1 { font-size: 150%; }
h2 { font-size: 130%; }
h3 { font-size: 100%; }

p, h1, h2, h3, h4, h5, h6, ul {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

p, ul {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

li {
  list-style-position: inside;
}

q, blockquote, cite {
  display: block;
  background: #ddd;
  margin-left: 1em;
  padding: 0.5em;
  width: 60%;

  p {
    width: auto;
  }
}

em {
  font-style: italic;
}

strong {
  font-style: italic;
  font-weight: bold;
}

//
// structural tag styles
//


.container {
  margin: 0;
  padding: 0;

  width: $grid-width;
  max-width: 960px;

  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}


.container:after {
  content: "";
  display: table;
  clear: both;
}


section {
  margin-bottom: 1em;
  @extend .container;

  header {
    margin-bottom: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.2em;
    font-size: 24pt;
    text-align: center;
    color: $background-colour;
    background: $base-colour;

    @include cell( 0, 12 );
  }

  article {
    background: $section-background-colour;
    @include cell( 0, 9 );
    @include debug( #f00 );
  }

  nav {
    //    background: #aaa;
    @include cell( 9, 3 );
    @include debug( #00f );
  }

  nav > ul {
    margin: 0;
  }

  nav > ul > li {
    width: 100%;
    background: $base-colour;
  }

  nav > ul > li > a {
    text-align: center;
    font-size: larger;
    display: block;
    color: $background-colour;

    border-color: $lowlight-colour;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    
    width: 100%;
    text-decoration: none;   

    &:link, &:visited {
      color: $background-colour;
    }
  }

  nav > ul > li > ul {
    background: $background-colour;
    margin: 0;

    li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      a {
        font-size: smaller;
        text-decoration: none;
      }
    }
  }
}

footer {
  @extend .container;
  @include debug( #360 );
}

.watermark {
  position: absolute;
  opacity: 0.3;
  width: 100%;
  text-align: center;
  z-index: 1000;
  //    font-size: 48pt;
  text-align: center;
  color: $background-colour;

  @media screen and ( max-width: 320px ) {
    font-size: 200%;
  }

  @media screen and ( min-width: 960px ) {
    font-size: 400%;
  }
}





#disqus_thread {
  @extend .container;
  margin-bottom: 2em;
}

