//
// variables and mixins
//

// font selections

//$font-family: 'Raleway', 'sans-serif';
//$font-family: 'Montserrat Alternates', 'sans-serif';
//$font-family: 'Montserrat Alternates', 'sans-serif';
$font-family: 'Montserrat', 'sans-serif';

$font-weight: 400;
//$font-weight: 600;



$foreground-colour: #000;

//$base-colour: #ff4f00;
//$base-colour: #009999;
//$base-colour: #1abc9c;
$base-colour: #14d100;

$hilight-colour: lighten( $base-colour, 10 );
$lowlight-colour: darken( $base-colour, 20 );
$background-colour: #f6f6f6;
$section-background-colour: $background-colour;

$fore-colour: $foreground-colour;


//
// grid system
//

//$grid-width: 960px;

//$grid-margin: 20px;
//$grid-padding: 10px;

// these numbers have been plucked out of the air, they need tuning....
$grid-width: 100%;
$grid-margin: 5%;
$grid-padding: 2%;

// 80px cell
$cell-count: 12;
$cell-width: calc($grid-width / $cell-count);

@mixin cell($offset,$length) {
  float: left;
  
  @if $offset + $length < $cell-count {
    margin-right: $grid-margin;
    width: $length * $cell-width - $grid-margin;
//    width: $length * $cell-width - ($grid-padding * 2) - $grid-margin;
  } @else {
    width: $length * $cell-width;
//    width: $length * $cell-width - ($grid-padding * 2);
  }

//  padding: $grid-padding;
}

@mixin debug($colour) {
  border-style: none;
//  border-style: dotted;
  border-width: 4px;
  border-color: $colour;
}

